/**
 * @generated SignedSource<<972691c498bd37bfb99760779f9d66bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneratedBonusTitle_offer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useGeneratedBonusTitleQuery">;
  readonly " $fragmentType": "GeneratedBonusTitle_offer";
};
export type GeneratedBonusTitle_offer$key = {
  readonly " $data"?: GeneratedBonusTitle_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneratedBonusTitle_offer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Game",
  "kind": "LinkedField",
  "name": "game",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playAmount",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "reward",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tickets",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketValue",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "BingoTicketsOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "matchPercentage",
              "storageKey": null
            }
          ],
          "type": "MatchDepositBonusMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bonusAmount",
              "storageKey": null
            }
          ],
          "type": "BonusMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "realAmount",
              "storageKey": null
            }
          ],
          "type": "RealMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spins",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spinValue",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monetaryType",
              "storageKey": null
            }
          ],
          "type": "FreespinOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/)
          ],
          "type": "FreeBetOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "boostedOdds",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "OddsBoostOfferReward",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BonusOfferStep",
  "abstractKey": "__isBonusOfferStep"
},
v4 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneratedBonusTitle_offer",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useGeneratedBonusTitleQuery",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isProgramGroup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProgramRecurrence",
          "kind": "LinkedField",
          "name": "recurrence",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "availableMaxTimes",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "steps",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minDepositAmount",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "type": "DepositOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mustDeposit",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "type": "RecentDepositOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "ClaimOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "ImmediateOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "TurnoverOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "type": "PlayRealMoneyOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "playRounds",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "type": "PlayRealRoundsOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "type": "SafeBetOfferStep",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "IBonusOffer",
  "abstractKey": "__isIBonusOffer"
};
})();

(node as any).hash = "0488d7ed09f8b5455c79c0600d4a7c4e";

export default node;
