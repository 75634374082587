/**
 * @generated SignedSource<<9672bff98e961e56d856551770606613>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGeneratedBonusTitle_reward$data = {
  readonly reward: {
    readonly __typename: "BingoTicketsOfferReward";
    readonly game: {
      readonly name: string;
    } | null;
    readonly ticketValue: number;
    readonly tickets: number;
  } | {
    readonly __typename: "BonusMoneyOfferReward";
    readonly bonusAmount: number;
  } | {
    readonly __typename: "FreeBetOfferReward";
    readonly game: {
      readonly name: string;
    } | null;
    readonly playAmount: number;
  } | {
    readonly __typename: "FreespinOfferReward";
    readonly game: {
      readonly name: string;
    } | null;
    readonly monetaryType: string | null;
    readonly spinValue: number;
    readonly spins: number;
  } | {
    readonly __typename: "MatchDepositBonusMoneyOfferReward";
    readonly matchPercentage: number;
  } | {
    readonly __typename: "OddsBoostOfferReward";
    readonly boostedOdds: number;
    readonly game: {
      readonly name: string;
    } | null;
  } | {
    readonly __typename: "RealMoneyOfferReward";
    readonly realAmount: number;
  } | {
    readonly __typename: "RefundRealMoneyOfferReward";
    readonly __typename: "RefundRealMoneyOfferReward";
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly " $fragmentType": "useGeneratedBonusTitle_reward";
};
export type useGeneratedBonusTitle_reward$key = {
  readonly " $data"?: useGeneratedBonusTitle_reward$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGeneratedBonusTitle_reward">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Game",
  "kind": "LinkedField",
  "name": "game",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGeneratedBonusTitle_reward",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "reward",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tickets",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketValue",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "type": "BingoTicketsOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "matchPercentage",
              "storageKey": null
            }
          ],
          "type": "MatchDepositBonusMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bonusAmount",
              "storageKey": null
            }
          ],
          "type": "BonusMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "realAmount",
              "storageKey": null
            }
          ],
          "type": "RealMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spins",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spinValue",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monetaryType",
              "storageKey": null
            }
          ],
          "type": "FreespinOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "playAmount",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "type": "FreeBetOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "boostedOdds",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "type": "OddsBoostOfferReward",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BonusOfferStep",
  "abstractKey": "__isBonusOfferStep"
};
})();

(node as any).hash = "35bc7b6eddae097c2c39916f857a2134";

export default node;
